import React, { useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { Page, Checkbox } from '@AUVOI_FLOW/components';
// eslint-disable-next-line import/no-unresolved
import { localizedString } from '@languages';

import parse from 'html-react-parser';
import classes from './Privacy.style.module.scss';

const Privacy = ({ privacyContent, onAccept, onSelectLanguage }) => {
  const [state, setState] = useState({ confirmed: false });
  const { backurl } = document.body.dataset;
  const buttons = [
    {
      label: localizedString('continue'),
      disabled: !state.confirmed,
      onClick: () => {
        if (state.confirmed) {
          onAccept();
        }
      },
      dataTestId: 'privacy-continue'
    }
  ];

  if (backurl) {
    buttons.unshift({
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => {
        window.location.href = backurl;
      }
    });
  }

  const { APP_NAME } = process.env;

  let privacyContentElements;
  if (!privacyContent) {
    const privacyContents = localizedString('privacyContents');
    const contents = privacyContents[APP_NAME].voi || [];
    privacyContentElements = contents.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} data-testid={`privacy-consent-${index}`}>
          {parse(item)}
          <br />
          <br />
        </div>
      );
    });
  } else {
    privacyContentElements = parse(privacyContent);
  }

  return (
    <Page
      title={localizedString('privacyTitle')}
      isPrivacy
      buttons={buttons}
      className={classes.wrapper}
      onSelectLanguage={onSelectLanguage}
    >
      <h3 data-testid="privacy-heading">{localizedString('privacyConsent')}</h3>
      <div className={classes.content}>
        {privacyContentElements}

        <Checkbox
          id="confirm"
          value={state.confirmed}
          onChange={(value) => setState({ confirmed: value })}
          label="I confirm that I have read and accept the privacy statement, and consent for OCR Labs to perform this identity verification check."
          dataTestId="privacy-chkBx"
          labelDataTestId="privacy-consentTxt"
        />
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  privacyContent: PropTypes.any,
  onSelectLanguage: PropTypes.func
};

export default Privacy;
